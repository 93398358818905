import {useCallback, useMemo} from 'preact/hooks';

import {CORE_AUTH_DOMAIN, PAY_AUTH_DOMAIN} from '~/constants/authorize';
import {useI18nContext} from '~/foundation/I18n/hooks';
import {useRootProvider} from '~/foundation/RootProvider/hooks';
import type {BuildAuthorizeUrlParams} from '~/types/authorizeUrlParams';
import {buildUrlSearchParams} from '~/utils/buildUrlSearchParams';

export function useAuthorizeUrl({
  analyticsContext,
  avoidPayAltDomain = false,
  avoidSdkSession = false,
  disableSignUp = false,
  flow = 'default',
  flowVersion = 'unspecified',
  error,
  ...props
}: BuildAuthorizeUrlParams) {
  const {locale} = useI18nContext();
  const {instanceId} = useRootProvider();

  const buildAuthorizeUrl = useCallback(
    (additionalProps?: Partial<BuildAuthorizeUrlParams>) => {
      const payload = {
        analyticsContext,
        analyticsTraceId: instanceId,
        avoidPayAltDomain,
        avoidSdkSession,
        disableSignUp,
        flow,
        flowVersion,
        locale,
        error,
        ...props,
        ...additionalProps,
      } as BuildAuthorizeUrlParams;

      if (!payload.proxy && payload?.clientId === undefined) {
        return '';
      }

      const params = buildUrlSearchParams(payload);

      if (payload.proxy) {
        return `${CORE_AUTH_DOMAIN}/services/login_with_shop/authorize?${params}`;
      }

      if (shouldUseNewUI(payload)) {
        return `${PAY_AUTH_DOMAIN}/oauth/authorize?${params}`;
      }

      /**
       * When `avoidPayAltDomain` is false, we need to route requests through the shop.app/pay/sdk-session endpoint.
       * The session endpoint will allow us to check for the presence of a Pay user session  and hoist it to the
       * "alt" pay.shopify.com/pay/sdk-authorize endpoint.
       */
      const path = payload.avoidPayAltDomain
        ? '/pay/sdk-authorize'
        : '/pay/sdk-session';

      return `${PAY_AUTH_DOMAIN}${path}?${params}`;
    },
    [
      analyticsContext,
      avoidPayAltDomain,
      avoidSdkSession,
      disableSignUp,
      error,
      flow,
      flowVersion,
      instanceId,
      locale,
      props,
    ],
  );

  const authorizeUrl = useMemo(() => buildAuthorizeUrl(), [buildAuthorizeUrl]);

  return {
    authorizeUrl,
    buildAuthorizeUrl,
  };
}

// For new customer accounts, if uxMode is windoid or redirect,
// deliver the new login UI, except for the PreAuthPrompt
function shouldUseNewUI(payload: BuildAuthorizeUrlParams) {
  return (
    payload.analyticsContext === 'loginWithShopSelfServe' &&
    payload.uxMode !== 'iframe' &&
    payload.uxRole !== 'prompt'
  );
}
